<template>
  <div class="info" id="watermark">
    <top-bar :title="'场所详情'" :left="true"></top-bar>

    <van-popup v-model="typeShow" position="bottom" >
      <van-cascader
        :field-names="fieldNames"
        v-model="cascaderValue"
        title="请选择"
        :options="list.placeTypeList"
        @close="typeShow = false"
        @change="typeConfirm"
      />
    </van-popup>

    <van-popup
      v-model="houseTypeShow"
      position="bottom">
      <van-picker
        title="请选择"
        show-toolbar
        :columns="list.placeHouseTypeList"
        value-key="label"
        @confirm="houseTypeConfirm"
        @cancel="houseTypeShow = false"
      />
    </van-popup>

    <van-popup
      v-model="importantShow"
      position="bottom">
      <van-picker
        v-model="importantShow"
        title="请选择"
        show-toolbar
        value-key="label"
        :columns="list.isList"
        @cancel="importantShow = false"
        @confirm="importantConfirm"
      />
    </van-popup>

    <van-popup
      v-model="importantTypeShow"
      position="bottom">
      <van-picker
        v-model="importantTypeShow"
        title="请选择"
        show-toolbar
        value-key="label"
        :columns="list.importantTypeList"
        @cancel="importantTypeShow = false"
        @confirm="importantTypeConfirm"
      />
    </van-popup>

    <van-popup
      v-model="trinityShow"
      position="bottom">
      <van-picker
        title="请选择"
        show-toolbar
        :columns="list.isList"
        value-key="label"
        @confirm="trinityConfirm"
        @cancel="trinityShow = false"
      />
    </van-popup>

    <van-popup
      v-model="categoryShow"
      position="bottom">
      <van-picker
        title="请选择"
        show-toolbar
        :columns="list.categoryList"
        value-key="label"
        @confirm="categoryConfirm"
        @cancel="categoryShow = false"
      />
    </van-popup>

    <van-popup
      v-model="subareaShow"
      position="bottom">
      <van-picker
        title="请选择"
        show-toolbar
        :columns="list.orgList"
        value-key="label"
        @confirm="subareaConfirm"
        @cancel="subareaShow = false"
      />
    </van-popup>

    <!-- 选择地址 -->
    <van-popup v-model="buildingShow" position="bottom" @click-overlay="buildingOverlay">
      <van-cascader v-model="dataForm.building" title="请选择" :options="list.buildingList"
                    @close="buildingShow = false" @change="buildingFinish" :field-names="fieldNames"/>
    </van-popup>

    <van-popup
      v-model="hasLicenseShow"
      position="bottom">
      <van-picker
        title="请选择"
        show-toolbar
        :columns="list.isList"
        value-key="label"
        @confirm="hasLicenseConfirm"
        @cancel="hasLicenseShow = false"
      />
    </van-popup>

    <van-popup
      v-model="safetyPerilShow"
      position="bottom">
      <van-picker
        v-model="safetyPerilShow"
        title="请选择"
        show-toolbar
        value-key="label"
        :columns="list.safetyPerilList"
        @cancel="safetyPerilShow = false"
        @confirm="safetyPerilConfirm"
      />
    </van-popup>

    <van-popup
      v-model="scaleShow"
      position="bottom">
      <van-picker
        v-model="scaleShow"
        title="请选择"
        show-toolbar
        value-key="label"
        :columns="list.isList"
        @cancel="scaleShow = false"
        @confirm="scaleConfirm"
      />
    </van-popup>

    <van-popup
      v-model="hazardousShow"
      position="bottom">
      <van-picker
        v-model="hazardousShow"
        title="请选择"
        show-toolbar
        value-key="label"
        :columns="list.isList"
        @cancel="hazardousShow = false"
        @confirm="hazardousConfirm"
      />
    </van-popup>

    <van-popup
        v-model="codeShow"
        position="bottom">
      <van-picker
          title="请选择"
          show-toolbar
          :columns="list.codeList"
          value-key="label"
          @confirm="codeConfirm"
          @cancel="codeShow = false"
      />
    </van-popup>

    <div class="border-content" v-show="!gridShow">
      <template v-if="changeType == 1">
        <van-cell-group class="info-second">
          <van-cell class="custom">
            <template #title>
              <span class="custom-title">基础信息</span>
              <van-button type="info" size="small" round @click="disable1=!disable1" v-if="disable1 == true">编辑</van-button>
              <van-button type="info" size="small" round @click="submit" v-if="disable1 == false">保存</van-button>
            </template>
          </van-cell>
          <van-cell title="所属机构" :value="selectMsg.subareaStr" @click="disable1 ? (subareaShow = false) : (subareaShow = true)"
                    :value-class="{'value-common':selectMsg.subareaStr=='请选择'}" :is-link="!disable1"/>
          <van-cell title="所属楼幢房屋" :value="selectMsg.buildingStr" @click="disable1 ? (buildingShow = false) : (buildingShow = true)"
                    :value-class="{'value-common':selectMsg.buildingStr=='请选择'}" :is-link="!disable1"/>
          <van-cell title="楼幢房屋类型" :value="selectMsg.houseTypeStr" @click="disable1 ? (houseTypeShow = false) : (houseTypeShow = true)"
                    :value-class="{'value-common':selectMsg.houseTypeStr=='请选择'}" :is-link="!disable1" required/>
          <van-cell title="场所类型" :value="selectMsg.placeTypeStr" @click="disable1 ? (typeShow = false) : (typeShow = true)"
                    :value-class="{'value-common':selectMsg.placeTypeStr=='请选择'}" :is-link="!disable1" required/>
          <van-cell title="常用名称" required>
            <van-field clearable clear-trigger="always" :readonly="disable1" v-model="dataForm.name" placeholder="请输入" style="text-align: right"/>
          </van-cell>
          <van-cell title="正式名称">
            <van-field clearable clear-trigger="always" :readonly="disable1" v-model="dataForm.licenseName" placeholder="请输入" style="text-align: right"/>
          </van-cell>
          <van-cell title="是否重要场所" :value="selectMsg.importantStr" @click="disable1 ? (importantShow = false) : (importantShow = true)"
                    :value-class="{'value-common':selectMsg.importantStr=='请选择'}" :is-link="!disable1" required/>
          <van-cell title="是否三合一场所" :value="selectMsg.trinityStr" @click="disable1 ? (trinityShow = false) : (trinityShow = true)"
                    :value-class="{'value-common':selectMsg.trinityStr=='请选择'}" :is-link="!disable1" required/>
          <van-cell title="重点属性" :value="selectMsg.importantTypeStr" @click="disable1 ? (importantTypeShow = false) : (importantTypeShow = true)"
                    :value-class="{'value-common':selectMsg.importantTypeStr=='请选择'}" :is-link="!disable1" required/>
          <van-cell title="详细地址" :border="false" class="cellClass" required/>
          <van-field clearable clear-trigger="always" :readonly="disable1" v-model="dataForm.address" placeholder="请输入" style="text-align: right"/>
          <template v-if="isCodeShow">
            <van-cell title="标准地址名称">
              <van-field clearable type="textarea" autosize rows="1" clear-trigger="always" :readonly="disable1" v-model="selectMsg.codeName"
                         @blur="dataForm.address = dataForm.codeName" placeholder="请输入" style="text-align: right"/>
            </van-cell>
            <p v-if="isTipShow" style="line-height: 0.3rem;margin: 0.1rem 0 0 0.5rem;color: #F56C6C;font-size: 14px;">{{tipValue}}</p>
            <van-cell title="标准地址码" :value="selectMsg.codeStr || '请选择'" @click="disable1 ? false : getCode()"
                      :value-class="{'value-common':selectMsg.codeStr=='请选择'}" :is-link="!disable1"/>
          </template>

        </van-cell-group>
        <!-- 自定义字段 -->
        <field-view ref="fieldView" @fatherChange="disable2Change" :disabled="disable2" :id="dataForm.id"  @submit="submit"/>
        <van-cell-group class="info-second">
          <van-cell class="custom">
            <template #title>
              <span class="custom-title">标签信息</span>
              <van-button type="info" size="small" round @click="disable3=!disable3" v-if="disable3 == true">编辑</van-button>
              <van-button type="info" size="small" round @click="submit" v-if="disable3 == false">保存</van-button>
            </template>
          </van-cell>
          <van-cell
              readonly
              clickable
              name="标签管理"
              :value="selectMsg.labelStr || '请选择'"
              title="标签管理"
              :is-link="!disable3"
              @click="showlabel"
          />
          <van-popup v-model="label" position="bottom" @click-overlay="labelOverlay">
            <van-cascader
                title="请选择"
                value="value"
                :field-names="{ text: 'label', value: 'value', children: 'children' }"
                :options="list.userCommunityLabelTree"
                active-color="#1989fa"
                @close="labelClose"
                @change="labelFinish"
            />
          </van-popup>

          <van-cell hover-class="none" v-if="labels.length>0">
            <template v-for="item in labels">
              <div class="vanTag" v-if="item.rightShow && !item.delete" :key="item.id" >
                <span :style="disable3 ? 'border: none' : ''">{{ item.name }}</span>
                <van-icon style="padding: 0 10px" name="cross" v-if="!disable3" @click="removeLabel(item.value)"/>
              </div>
            </template>
          </van-cell>
          <van-row class="uploadBox">
            <van-col :span="24">
              <van-uploader
                  v-model="urls"
                  :readonly="disable3"
                  @delete="uploaddel"
                  :after-read="uploadRead"
                  :deletable="!disable3"
                  :show-upload="!disable3"
              />
            </van-col>
          </van-row>
        </van-cell-group>
        <van-cell-group class="info-second">
          <van-cell class="custom">
            <template #title>
              <span class="custom-title">其他信息</span>
              <van-button type="info" size="small" round @click="disable4=!disable4" v-if="disable4 == true">编辑</van-button>
              <van-button type="info" size="small" round @click="submit" v-if="disable4 == false">保存</van-button>
            </template>
          </van-cell>
          <van-cell title="面积">
            <van-field clearable clear-trigger="always" :readonly="disable4" v-model="dataForm.areas" placeholder="请输入" style="text-align: right"/>
          </van-cell>
          <van-cell title="职工人数">
            <van-field clearable clear-trigger="always" :readonly="disable4" v-model="dataForm.staffNum" placeholder="请输入" type="digit" style="text-align: right"/>
          </van-cell>
          <van-cell title="主管单位">
            <van-field clearable clear-trigger="always" :readonly="disable4" v-model="dataForm.competentUnit" placeholder="请输入" style="text-align: right"/>
          </van-cell>
          <van-cell title="用途">
            <van-field clearable clear-trigger="always" :readonly="disable4" v-model="dataForm.purpose" placeholder="请输入" style="text-align: right"/>
          </van-cell>
          <van-cell title="风险等级">
            <van-field clearable clear-trigger="always" :readonly="disable4" v-model="dataForm.level" placeholder="请输入" style="text-align: right"/>
          </van-cell>
          <van-cell title="备注" :border="false" class="cellClass"/>
          <van-field clearable clear-trigger="always" :readonly="disable4" v-model="dataForm.remark" placeholder="请输入" style="text-align: right"/>

        </van-cell-group>
      </template>

    <van-cell-group class="info-second" v-if="changeType == 2">
      <van-cell class="custom">
        <template #title>
          <span class="custom-title">联络信息</span>
          <van-button type="info" size="small" round @click="disable5=!disable5" v-if="disable5 == true">编辑</van-button>
          <van-button type="info" size="small" round @click="submit" v-if="disable5 == false">保存</van-button>
        </template>
      </van-cell>
        <van-cell title="主要联系人" required>
          <van-field clearable clear-trigger="always" :readonly="disable5" v-model="dataForm.contact" placeholder="请输入" style="text-align: right"/>
        </van-cell>
        <van-cell title="联系人电话" required>
          <van-field clearable clear-trigger="always" :readonly="disable5" v-model="dataForm.contactMobile" placeholder="请输入" style="text-align: right"/>
        </van-cell>
        <van-cell title="法人/负责人">
          <van-field clearable clear-trigger="always" :readonly="disable5" v-model="dataForm.legal" placeholder="请输入" style="text-align: right"/>
        </van-cell>
        <van-cell title="法人/负责人电话">
          <van-field clearable clear-trigger="always" :readonly="disable5" v-model="dataForm.legalMobile" placeholder="请输入" style="text-align: right"/>
        </van-cell>
    </van-cell-group>
<!--A社区-演示小区1-1-101-->
      <van-cell-group v-if="!!dataForm.gridId && changeType == 3">
      <div class="placeInfo" v-if="codeType == 2">
        <div class="top" v-if="selectMsg.codeMappingEntity">
          <p><span>{{selectMsg.communityName}}</span><span v-if="selectMsg.subareaName">{{'-' + selectMsg.subareaName}}</span><span v-if="selectMsg.buildingName">{{'-' + selectMsg.buildingName + '幢'}}</span></p>
          <van-row class="content">
            <van-col :span="24">地址信息</van-col>
            <van-col :span="24">街路巷弄：{{selectMsg.codeMappingEntity.road}}</van-col>
            <van-col :span="24">门牌号：{{selectMsg.codeMappingEntity.doorNumber}}</van-col>
            <van-col :span="24">院落名：{{selectMsg.codeMappingEntity.subarea}}</van-col>
            <van-col :span="24">地址编码：{{selectMsg.codeMappingEntity.subarea}}</van-col>
          </van-row>
        </div>
        <div class="top topInfo" v-if="!selectMsg.codeMappingEntity">
          <p><span>{{selectMsg.communityName}}</span><span v-if="selectMsg.subareaName">{{'-' + selectMsg.subareaName}}</span><span v-if="selectMsg.buildingName">{{'-' + selectMsg.buildingName}}</span></p>
        </div>

        <div class="bottom" v-if="selectMsg.codeMappingEntity">
<!--          <van-tag type="primary" text-color="#4581F8" color="rgba(78,124,245,0.12)" style="border-radius: 2px;padding: 3px 5px">空</van-tag>-->
          <span class="grid">所属网格：<i>{{selectMsg.gridName}}</i></span>
        </div>
        <div class="bottomInfo" v-if="!selectMsg.codeMappingEntity">
          <span class="grid">所属网格：<i>{{selectMsg.gridName}}</i></span>
        </div>
      </div>
      <van-cell class="custom" style="margin-top: 10px;">
        <template #title>
          <span class="custom-title">网格信息</span>
          <van-button type="info" size="small" round @click.self="disable6=!disable6" v-if="disable6 == true">编辑</van-button>
          <van-button type="info" size="small" round @click.self="submit" v-if="disable6 == false">保存</van-button>
        </template>
      </van-cell>
      <van-cell title="所属网格" class="gridBtn" :value="selectMsg.gridName || '请选择'" @click="disable6 ? (gridShow = false) : (gridShow = true)"
                :value-class="{'value-common':selectMsg.gridName=='请选择'}" :is-link="!disable6">
<!--        <template>-->
<!--          <van-button type="info" size="small" round @click.self="disable6=!disable6" v-if="disable6 == true">编辑</van-button>-->
<!--          <van-button type="info" size="small" round @click.self="submit" v-if="disable6 == false">保存</van-button>-->
<!--        </template>-->
      </van-cell>
      <van-cell :title="item.postStr || item.managerTypeStr" class="gridUser" v-for="(item, index) in selectMsg.gridUsers" :key="index">
        <van-field clearable clear-trigger="always" readonly v-model="item.name || '无'" placeholder="请输入" style="text-align: right">
          <template #right-icon>
            <a :href="'tel:'+item.mobile" @click.stop=""><img src="@/assets/img/mobile.png" alt="" class="tel"/></a>
          </template>
        </van-field>
      </van-cell>
    </van-cell-group>
    </div>
    <div class="check-info-wrap">
      <div class="check-title">选择信息</div>
      <div class="detail-info-wrap">
        <div class="info-item" :class="changeType === 1 ? 'checked' : ''" @click="changeCondition(1)">基础信息</div>
        <div class="info-item" :class="changeType === 2 ? 'checked' : ''" @click="changeCondition(2)">联系信息</div>
        <div class="info-item" v-if="!!dataForm.gridId" :class="changeType === 3 ? 'checked' : ''" @click="changeCondition(3)">网格信息</div>
      </div>
    </div>
    <grid-select v-show="gridShow" :list="list.gridList" @selectLabel="selectGrid" :isArray="1" ref="gridSelect"></grid-select>
  </div>
</template>
<script>
import topBar from "@/components/topBar/topBar";
import { getbelongGrid, getDictTree, getbelongSubArea, getVirtualDict, listComRightLabel, getMiniGrid } from "@/utils/common";
import fieldView from '../../components/field-view/field-view.vue';
import { getImageStream, formatLabel } from "@/utils/index";
import GridSelect from '../userRes/grid-select.vue'
import { isNumber } from "@/utils/validate";
let that
export default {
  components: {
    topBar,
    fieldView,
    GridSelect
  },
  created() {
    this.$watermark.set(this.$globalData.userInfo.userName, this.$globalData.userInfo.mobile)
    if (this.$route.query.id) {
      (this.disable1 = true, this.disable2 = true, this.disable3 = true, this.disable4 = true, this.disable5 = true, this.disable6 = true), (this.dataForm.id = this.$route.query.id);
    }
    this.street = this.$globalData.userInfo.street
    this.initOrgId = this.$globalData.userInfo.orgId
    this.codeType = this.$globalData.userInfo.codeType
    this.isCodeShow = this.$globalData.userInfo.codeType == 2 ? true : false
    this.getInfo();
    that = this;
    getbelongSubArea(this.$orgId, function (e) {
      that.list.orgList = that.list.orgList.concat(e);
    });
    getbelongGrid({ orgId: this.$orgId }, function (e) {
      that.list.gridList = that.list.gridList.concat(e);
    });
    getDictTree({ code: "placeType" }, function (e) {
      that.list.placeTypeList = that.list.placeTypeList.concat(e);
    });
    getVirtualDict('importantType', function (e) {
      that.list.importantTypeList = e
    })
    getVirtualDict('safetyPeril', function (e) {
      that.list.safetyPerilList = e
    })
    listComRightLabel(function (labels) {
      that.list.userCommunityLabelTree = labels
    }, 3)
    getMiniGrid({community:this.$orgId}, function (list) {
      that.list.gridList = list
    })
    that.getPlaceHouseType();
  },
  data() {
    return {
      changeType: 1, // 切换信息
      firstVisible: true,
      secondVisible: true,
      fieldNames: { text: "label", value: "value", children: "children" },
      cascaderValue: [],
      disable1: false,
      disable2: false,
      disable3: false,
      disable4: false,
      disable5: false,
      disable6: false,
      typeShow: false,
      houseTypeShow: false,
      importantShow: false,
      importantTypeShow: false,
      trinityShow: false,
      categoryShow: false,
      subareaShow: false,
      buildingShow: false,
      hasLicenseShow: false,
      safetyPerilShow: false,
      scaleShow: false,
      hazardousShow: false,
      gridShow: false,
      codeShow: false,
      isCodeShow: false,
      isTipShow: false,
      tipValue: '',
      fileList: [],
      street: 0,
      initOrgId: 0,
      codeMappingId: 0,
      dataForm: {
        id: "",
        name: "",
        licenseName: "",
        type: "",
        houseType: "",
        contact: "",
        contactMobile: "",
        legal: "",
        legalMobile: "",
        important: "0",
        trinity: "0",
        address: "",
        community: "",
        subarea: "",
        buildingId: "1",
        hasLicense: 0,
        licenseCode: "",
        hygieneCode: "",
        safetyDirectorName: '',
        safetyDirectorMobile: '',
        gasNum: '',
        fireNum: '',
        competentUnit: '',
        remark: "",
        imgUrl: "",
        category: "",
        newFileMessages: [],
        deleteFiles: [],
        importantType: '',
        safetyPeril: '',
        isScaleCompany: '0',
        isHazardousGoodsCompany: '0',
        staffNum: 0,
        labels: [],
        gridId: 0,
        miniGridId: 0,
        areas: '',
        purpose: '',
        level: '',
        code: '',
        buildingDepth: 0
      },
      label: false,
      labels: [],
      temporaryLabel: null,
      temporaryBuilding: null,
      urls: [],
      codeType: null,
      selectMsg: {
        placeTypeStr: "请选择",
        houseTypeStr: "请选择",
        trinityStr: "否",
        importantStr: "否",
        categoryStr: "请选择",
        communityStr: "未知",
        subareaStr: "请选择",
        buildingStr: "无",
        hasLicenseStr: "否",
        importantTypeStr: '请选择',
        safetyPerilStr: '请选择',
        scaleStr: '否',
        hazardousStr: '否',
        labelStr: '请选择',
        gridName: '请选择',
        gridUser: '',
        gridUsers: [],
        code: '',
        codeMappingEntity: {},
        communityName: '',
        subareaName: '',
        buildingName: '',
        codeStr: '请选择',
        codeName: ''
      },
      list: {
        isList: [
          { value: "0", label: "否" },
          { value: "1", label: "是" },
        ],
        categoryList: [
          { value: "1", label: "场所" },
          { value: "2", label: "企业" },
          { value: "3", label: "个体工商户" },
        ],
        orgList: [],
        gridList: [],
        placeTypeList: [],
        placeHouseTypeList: [],
        publicBuildingList: [],
        importantTypeList: [],
        safetyPerilList: [],
        userCommunityLabelTree: []
      },
    };
  },
  methods: {
    changeCondition(type) {
      this.changeType = type
      if (type == 1) {
        // 自定义字段
        this.$nextTick(() => {
          this.$refs.fieldView.init(this.dataForm.id, 3, this.$orgId)
        })
      }
    },
    disable2Change(val) {
      this.disable2 = val
    },
    showlabel() {
      if (!this.disable3) {
        this.label = true
      }
    },
    labelOverlay() {
      let temporaryLabel = this.temporaryLabel[0]
      if (!temporaryLabel) {
        return
      }
      for (let i in that.labels) {
        let label = that.labels[i]
        if (label.value == temporaryLabel.value) {
          return
        }
      }
      temporaryLabel["delete"] = false
      this.labels.push(temporaryLabel)
      this.dataForm.labels.push(temporaryLabel.ids)
    },
    labelClose(e) {
      this.label = false
    },
    labelFinish(e) {
      let value = []
      e.selectedOptions.map(e => value.push(e.value))
      let map = {}
      map["value"] = value
      map['options'] = e.selectedOptions
      let temporaryLabel = formatLabel(map, false)
      this.temporaryLabel = temporaryLabel
    },
    removeLabel(value) {
      for (let i in this.dataForm.labels) {
        let label = this.dataForm.labels[i] + ''
        let labelArray = label.split(",")
        let id = labelArray[labelArray.length - 1]
        if (id == value) {
          this.dataForm.labels.splice(i, 1)
        }
      }

      for (let i in this.labels) {
        let label = this.labels[i]
        if (label.value == value) {
          this.labels[i]["delete"] = true
        }
      }
      this.dataForm.labels = this.dataForm.labels
      this.labels = this.labels
      this.$forceUpdate()
    },
    getPlaceHouseType() {
      this.$http({
        url: this.$http.adornUrl("/wxapp/sys/dict/virtual/placeHouseType"),
        method: "get",
      }).then(({ data }) => {
        if (data.code == 0) {
          for (let i in data.placeHouseTypes) {
            let placeHouseType = data.placeHouseTypes[i];
            placeHouseType["label"] = placeHouseType.label;
            placeHouseType["value"] = placeHouseType.value + "";
          }
          this.list.placeHouseTypeList = data.placeHouseTypes;
        }
      });
    },
    getInfo() {
      if (this.dataForm.id) {
        this.$http({
          url: this.$http.adornUrl("/wxapp/place/info/info"),
          method: "post",
          params: this.$http.adornParams({
            id: this.dataForm.id,
          }),
        }).then(
          ({ data }) => {
            if (data.code == 0) {
              if (data.placeInfo.important == 1) {
                this.selectMsg.importantStr = "是";
              } else {
                this.selectMsg.importantStr = "否";
              }

              if (data.placeInfo.hasLicense == 1) {
                this.selectMsg.hasLicenseStr = "是";
              } else {
                this.selectMsg.hasLicenseStr = "否";
              }

              if (data.placeInfo.isScaleCompany == 1) {
                this.selectMsg.scaleStr = "是";
              } else {
                this.selectMsg.scaleStr = "否";
              }

              if (data.placeInfo.isHazardousGoodsCompany == 1) {
                this.selectMsg.hazardousStr = "是";
              } else {
                this.selectMsg.hazardousStr = "否";
              }

              if (data.placeInfo.trinity && data.placeInfo.trinity == 1) {
                this.selectMsg.trinityStr = "是";
              } else {
                this.selectMsg.trinityStr = "否";
              }

              if (data.placeInfo.category == 1) {
                this.selectMsg.categoryStr = "场所";
              } else if (data.placeInfo.category == 2) {
                this.selectMsg.categoryStr = "企业";
              } else if (data.placeInfo.category == 3) {
                this.selectMsg.categoryStr = "个体工商户";
              } else {
                this.selectMsg.categoryStr = "请选择";
              }
              console.log(this.selectMsg);
              this.dataForm = {...data.placeInfo, labels: []}
              this.selectMsg.placeTypeStr = data.placeInfo.typeName;
              let orgName = ''
              if (this.initOrgId == this.street) {
                this.dataForm.community = data.placeInfo.community
                if (data.placeInfo.subarea) {
                  orgName = data.placeInfo.communityName + '-' + data.placeInfo.subareaName
                } else {
                  orgName = data.placeInfo.communityName
                }
                this.getBuildingList(data.placeInfo.community)
              } else {
                this.dataForm.subarea = data.placeInfo.subarea
                if (data.placeInfo.subarea) {
                  orgName = data.placeInfo.communityName + '-' + data.placeInfo.subareaName
                } else {
                  orgName = data.placeInfo.communityName
                }
                this.getBuildingList(data.placeInfo.subarea)
              }
              this.selectMsg.subareaStr = orgName;
              this.selectMsg.houseTypeStr = data.placeInfo.houseTypeStr;
              this.selectMsg.importantTypeStr = data.placeInfo.importantTypeStr
              this.selectMsg.gridName = data.placeInfo.gridName
              this.selectMsg.gridUser = data.placeInfo.gridUser
              this.selectMsg.gridUsers = data.placeInfo.gridUsers
              this.selectMsg.codeMappingEntity = data.placeInfo.codeMappingEntity
              this.selectMsg.code = data.placeInfo.code
              this.selectMsg.codeName = data.placeInfo.codeMappingEntity ? data.placeInfo.codeMappingEntity.fullName : ''
              this.codeMappingId = data.placeInfo.codeMappingEntity ? data.placeInfo.codeMappingEntity.id : 0
              this.selectMsg.codeStr = data.placeInfo.code
              this.selectMsg.communityName = data.placeInfo.communityName || ''
              this.selectMsg.subareaName = data.placeInfo.subareaName || ''
              this.selectMsg.buildingName = data.placeInfo.buildingName !== null ? data.placeInfo.buildingName + "幢" : '无',
              this.selectMsg.buildingStr = data.placeInfo.buildingName != "无" ? data.placeInfo.buildingName + "幢" : "无";
              var placeLabels = data.labels
              var labels = []
              if (placeLabels != null) {
                labels = formatLabel(placeLabels, true);
              }
              that.labels = labels
              let idsArray = labels.map((item) => {
                item["delete"] = false
                return item.ids
              })
              that.dataForm.labels = that.dataForm.labels.concat(idsArray)
              // formData: { path: "files/place/" + app.globalData.userInfo.orgId }
              let u;
              let urls = [];
              //获取图片流
              if (data.placeInfo.imgUrl) {
                let imgUrls = data.placeInfo.imgUrl.split(",");

                for (let i in imgUrls) {
                  let u;
                  let file = imgUrls[i];
                  u = getImageStream(imgUrls[i]);
                  let url = {
                    uid: urls.length,
                    status: "done", //uploading上传中，done上传完成，error上传失败
                    url: u,
                    fileType: "image/png",
                    realUrl: file.replace(/\\/g, "/"),
                  };
                  urls.push(url);
                }
              }
              this.urls = urls;
              // 自定义字段
              this.$nextTick(() => {
                this.$refs.fieldView.init(this.dataForm.id, 3, this.$orgId)
              })
            }

            this.$toast.clear();
          },
          (err) => {
            this.$toast.clear();
          }
        );
      } else {
        // 自定义字段
        this.$nextTick(() => {
          this.$refs.fieldView.init(null, 3, this.$orgId)
        })
      }
    },
    // 经营类型选择
    typeConfirm({ selectedOptions }) {
      let value = selectedOptions[selectedOptions.length - 1];
      if (value) {
        this.selectMsg.placeTypeStr = value.label;
        this.dataForm.type = value.value;
      }
      if (!value.children || value.children.length == 0) {
        this.typeShow = false;
      }
    },
    // 场所房屋类型选择
    houseTypeConfirm(value) {
      if (value) {
        this.selectMsg.houseTypeStr = value.label;
        this.dataForm.houseType = value.value;
      }
      this.houseTypeShow = false;
    },
    // 是否重要场所选择
    importantConfirm(value) {
      if (value) {
        this.selectMsg.importantStr = value.label;
        this.dataForm.important = value.value;
      }
      this.importantShow = false;
    },
    importantTypeConfirm(value) {
      if (value) {
        this.selectMsg.importantTypeStr = value.label;
        this.dataForm.importantType = value.value;
      }
      this.importantTypeShow = false;
    },
    // 是否三合一场所选择
    trinityConfirm(value) {
      if (value) {
        this.selectMsg.trinityStr = value.label;
        this.dataForm.trinity = value.value;
      }
      this.trinityShow = false;
    },
    // 场所类别选择
    categoryConfirm(value) {
      if (value) {
        this.selectMsg.categoryStr = value.label;
        this.dataForm.category = value.value;
      }
      this.categoryShow = false;
    },
    // 所属小区选择
    subareaConfirm(value) {
      if (value) {
        this.selectMsg.subareaStr = value.label;
        if (this.street == this.initOrgId) {
          this.dataForm.community = value.value;
        } else {
          this.dataForm.subarea = value.value;
        }
      }
      this.subareaShow = false;
      this.getBuildingList(value.value);
    },
    // 详细地址选择
    buildingFinish(value) {
      let options = value.selectedOptions
      this.selectMsg.buildingStr = '请选择'
      this.dataForm.buildingId = ''
      let option = options[options.length - 1];
      if (option.depth !== 1 && option.depth !== 3) {
        let map = {}
        map["value"] = option.value
        map['label'] = option.label
        this.temporaryBuilding = map
        let type = option.depth == 2 ? 1 : 2
        this.dataForm.buildingDepth = option.depth
        this.getCodeById(option.value, type)
      }
    },
    buildingOverlay() {
      if (this.temporaryBuilding && this.temporaryBuilding.value !== '') {
        this.selectMsg.buildingStr = this.temporaryBuilding.label
        this.dataForm.buildingId = this.temporaryBuilding.value
      }
    },
    // 是否有营业执照
    hasLicenseConfirm(value) {
      if (value) {
        this.selectMsg.hasLicenseStr = value.label;
        this.dataForm.hasLicense = value.value;
      }
      this.hasLicenseShow = false;
    },
    safetyPerilConfirm (value) {
      if (value) {
        this.selectMsg.safetyPerilStr = value.label;
        this.dataForm.safetyPeril = value.value;
      }
      this.safetyPerilShow = false;
    },
    scaleConfirm (value) {
      if (value) {
        this.selectMsg.scaleStr = value.label;
        this.dataForm.isScaleCompany = value.value;
      }
      this.scaleShow = false;
    },
    hazardousConfirm (value) {
      if (value) {
        this.selectMsg.hazardousStr = value.label;
        this.dataForm.isHazardousGoodsCompany = value.value;
      }
      this.hazardousShow = false;
    },
    // //所属网格选择
    // gridConfirm(value) {
    //   if (value) {
    //     this.selectMsg.gridName = value.name;
    //     this.dataForm.gridId = value.id;
    //   }
    //   this.gridShow = false;
    // },
    // 网格选择
    selectGrid (e) {
      const {selectList, labelCondition, depth} = e
      if (e.labelCondition.length > 0) {
        this.dataForm.gridId = 0
        this.dataForm.miniGridId = 0
        this.selectMsg.gridName = labelCondition[0].label;
        this.dataForm.gridId = e.labelCondition[0].fatherValue || parseInt(selectList)
        this.dataForm.miniGridId = e.labelCondition[0].fatherValue ? parseInt(selectList) : 0
      }
      this.gridShow = false
    },
    codeConfirm (value){
      if (value) {
        this.selectMsg.codeStr = value.value;
        this.dataForm.code = value.value;
        this.selectMsg.codeName = value.label
        this.dataForm.address = value.label
        this.list.codeList.map(item => {
          if (item.value == value.value) {
            this.codeMappingId = item.codeMappingId
          }
        })
      }
      this.codeShow = false;
    },
    getCode() {
      this.list.codeList = []
      this.selectMsg.codeStr = '请选择'
      this.dataForm.code = ''
      this.isTipShow = false
      this.tipValue = ''
      let url = '/wxapp/sys/addressCodeMapping/listByName'
      let name = true
      if (isNumber(this.selectMsg.codeName)) {
        url = '/wxapp/sys/addressCodeMapping/infoByCode'
        name = false
      }
      this.$http({
        url: this.$http.adornUrl(url),
        method: 'post',
        params: this.$http.adornParams({
          name: name ? this.selectMsg.codeName : undefined,
          code: name ? undefined : this.selectMsg.codeName,
          type: 4
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          let list = (data.list || data.sysAddressCodeMapping).map(item => {
            return {
              value: item.houseCode ? item.houseCode : item.doorCode,
              label: item.fullName,
              codeMappingId: item.id
            }
          })
          if (name) {
            this.list.codeList = list
          } else {
            this.list.codeList = list
          }
          this.codeShow = true
        } else {
          this.isTipShow = true
          this.tipValue = data.msg
          // this.$toast.fail(data.msg)
        }
      })
    },
    // 获取房屋列表
    getBuildingList(orgId) {
      if (this.street == this.initOrgId) {
        this.$http({
          url: this.$http.adornUrl('/wxapp/building/house/liveWhichTree'),
          method: 'get',
          params: this.$http.adornParams({
            communityId: orgId,
          })
        }).then(({data}) => {
          if (data && data.code === 0) {
            this.list.buildingList = data.liveWhichTree
          } else {
            this.$toast.fail(data.msg)
          }
        })
      } else {
        this.$http({
          url: this.$http.adornUrl('/wxapp/building/house/bySubareaIdTree'),
          method: 'get',
          params: this.$http.adornParams({
            subareaId: orgId,
          })
        }).then(({data}) => {
          if (data && data.code === 0) {
            this.list.buildingList = data.liveWhichTree
          } else {
            this.$toast.fail(data.msg)
          }
        })
      }
    },
    getCodeById (id, type) {
      // 1 楼幢, 2 房屋
      this.$http({
        url: this.$http.adornUrl(`/wxapp/sys/addressCodeMapping/infoByIdAndType`),
        method: 'get',
        params: this.$http.adornParams({
          id: id,
          type: type
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          if (data.one) {
            if (type == 1) {
              this.dataForm.code = data.one.buildingCode
              this.selectMsg.codeStr = data.one.buildingCode
            } else {
              this.dataForm.code = data.one.houseCode
              this.selectMsg.codeStr = data.one.houseCode
            }
            this.selectMsg.codeName = data.one.fullName
            this.codeMappingId = data.one.id
          }
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
    // 删除图片
    uploaddel(file, detail) {
      let imgUrls = this.dataForm.imgUrl.split(",");
      imgUrls.splice(detail, 1);
      this.dataForm.imgUrl = imgUrls.join();
    },
    // 上传图片
    uploadRead(file) {
      let that = this;
      this.$toast.loading({
        message: "上传中...",
        forbidClick: true,
        duration: 0,
        overlay: true,
      });
      if (!Array.isArray(file)) {
        file = [file]
      }
      file.forEach((item, index2) => {
      let formdata = new FormData();
      formdata.append("file", item.file);
      formdata.append("path", `files/place/${this.$orgId}`);
      this.$http({
        url: this.$http.adornUrl("/wxapp/file/upload"),
        method: "post",
        data: formdata,
      }).then(({ data }) => {
          if (index2 === file.length - 1) {
            this.$toast.clear()
          }
        if (data.code == 0) {
          console.log(this.urls);
          // let uid = that.urls.length + 1;
          // let u = getImageStream(data.fileMessage.relativePath);
          // let url = {
          //   uid: uid,
          //   status: "done",
          //   url: u,
          // };
          // that.urls.push(url);
          that.dataForm.imgUrl = that.dataForm.imgUrl
            ? that.dataForm.imgUrl + "," + data.fileMessage.relativePath
            : data.fileMessage.relativePath;

          // this.dataForm.imgUrl += ','+data.fileMessage.relativePath;

          // this.dataForm.newFileMessages.push(data.fileMessage);
          return true;
        } else {
          that.$toast.fail(data.msg);
        }
      });
      })
    },
    async submit() {
      let url = "/wxapp/place/info/save";
      if (this.dataForm.id && this.dataForm.id != 0) {
        url = "/wxapp/place/info/update";
      }
      if (this.dataForm.name === '') {return this.$toast.fail('失败:场所名不能为空');}
      if (this.dataForm.type === '') {return this.$toast.fail('失败:经营类型不能为空');}
      if (this.dataForm.houseType === '') {return this.$toast.fail('失败:场所房屋类型不能为空');}
      if (this.dataForm.important === '') {return this.$toast.fail('失败:是否重点场所不能为空');}
      if (this.dataForm.importantType === '') {return this.$toast.fail('失败:重点属性不能为空');}
      if (this.dataForm.trinity === '') {return this.$toast.fail('失败:是否三合一场所不能为空');}
      if (this.dataForm.address === '') {return this.$toast.fail('失败:详细地址不能为空');}
      if (this.dataForm.contact === '') {return this.$toast.fail('失败:主要联系人不能为空');}
      if (this.dataForm.contactMobile === '') {return this.$toast.fail('失败:联系人电话不能为空');}

      let extendInfoList = null
      // 自定义字段参数
      if (this.$refs.fieldView) {
        extendInfoList = await this.$refs.fieldView.submitForm()
      }
      let buildingDepth = null
      if (this.dataForm.buildingDepth == 2) {
        buildingDepth = 1
      } else if (this.dataForm.buildingDepth == 4) {
        buildingDepth = 3
      }
      this.dataForm.buildingDepth = buildingDepth
      this.$http({
        url: this.$http.adornUrl(url),
        method: "post",
        data: {...this.dataForm, extendInfoList, labels: this.dataForm.labels.join(','), codeMappingId: this.codeMappingId || undefined},
      }).then(({ data }) => {
        if (data.code == 0) {
          this.$toast.success("操作成功");
          this.disable1 = true
          this.disable2 = true
          this.disable3 = true
          this.disable4 = true
          this.disable5 = true
          this.disable6 = true
        } else {
          this.$toast.fail(data.msg);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.border-content {
  padding-bottom: 220px;
}
.vanTag {
  margin: 0 0 5px 5px;
  border-radius: 5px;
  border: 1px solid #007AFF;
  background: rgba(0, 122, 255, 0.1);
  //padding-right: 20px;
  float: left;

  span {
    display: inline-block;
    padding: 0 17px;
    //margin-right: 20px;
    font-size: 24px;
    color: #007AFF;
    height: 56px;
    line-height: 56px;
    border-right: 1px solid #007AFF;
  }
}
.placeInfo {
  color: #fff;
  .top {
    position: relative;
    height: 320px;
    background: url('~@/assets/img/placeInfo.png') no-repeat center;
    background-size: 100% 100%;
    p {
      width: 100%;
      position: absolute;
      top: 24px;
      text-align: center;
      font-size: 28px;
      font-weight: 600;
    }
    .content {
      position: absolute;
      top: 90px;
      left: 44px;
      line-height: 40px;
      .van-col:first-child {
        font-size: 26px;
      }
    }
  }
  .topInfo {
    position: relative;
    top: 24px;
    margin: 0 30px;
    height: 90px;
    background: linear-gradient(225deg, #7BB7FC 0%, #4581F8 100%);
    box-shadow: 0px 0px 24px 0px rgba(113,134,177,0.5);
    border-radius: 12px 12px 0px 0px;
    p {
      width: 100%;
      position: absolute;
      top: 0;
      text-align: center;
      font-size: 28px;
      font-weight: 600;
    }
  }
  .bottom {
    position: relative;
    top: -20px;
    margin: 0 25px;
    height: 78px;
    padding: 25px 0 0 16px;
    background: #fff;
    border-radius: 0px 0px 12px 12px;
    box-shadow: 0 0 80px -3px rgba(140, 172, 236, 0.75);
    .grid {
      color: #666666;
      font-size: 28px;
      //margin-left: 24px;
      i {
        font-weight: 600;
        font-style: normal;
      }
    }
  }
  .bottomInfo {
    position: relative;
    top: 10px;
    margin: 0 30px 52px;
    height: 78px;
    line-height: 78px;
    text-align: center;
    background: #fff;
    border-radius: 0px 0px 12px 12px;
    box-shadow: 0 0 80px -3px rgba(140, 172, 236, 0.75);
    .grid {
      color: #666666;
      font-size: 28px;
      //margin-left: 24px;
      i {
        font-weight: 600;
        font-style: normal;
      }
    }
  }
}
.gridBtn {
  position: relative;
  //line-height: 66px;
  //::v-deep .van-icon-arrow{
  //  position: absolute;
  //  right: 160px;
  //}
  .van-button {
    position: absolute;
    right: 0;
    width: 116px;
    height: 58px;
    color: #4581F8;
    font-size: 30px;
    background: transparent;
    border-radius: 8px;
    border: 2px solid #4581F8;
  }
}
.gridUser {
  .tel {
    width: 40px;
    height: 40px;
  }
}
.info {
  ::v-deep .van-radio-group{
    float: unset !important;
  }
}
</style>
